<template>
  <div class="grid gap-2 pe-[2rem]">
    <label class="block text-[0.875rem] font-[600]">{{ `Min: ${(parseInt(currentMin)).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}` }}</label>
    <div class="relative h-[1rem]">
      <input
          ref="inputMinVal"
          v-model="currentMin"
          type="range"
          :min="minVal"
          :max="maxVal"
          :step="step"
          class="absolute w-[calc(100%-1rem)] cursor-pointer appearance-none focus:outline-none disabled:pointer-events-none disabled:opacity-50
              [&::-moz-range-thumb]:size-[0.75rem]

              [&::-moz-range-thumb]:appearance-none
              [&::-moz-range-thumb]:rounded-full
              [&::-moz-range-thumb]:bg-[#222222]
              [&::-moz-range-thumb]:transition-all
              [&::-moz-range-thumb]:duration-150
              [&::-moz-range-thumb]:ease-in-out
              [&::-moz-range-track]:h-[0.25rem]
              [&::-moz-range-track]:w-full
              [&::-moz-range-track]:rounded-full

              [&::-moz-range-track]:bg-[#F2F2F2]
              [&::-webkit-slider-container]:translate-y-[0.375rem]
              [&::-webkit-slider-runnable-track]:h-[0.25rem]
              [&::-webkit-slider-runnable-track]:w-full
              [&::-webkit-slider-runnable-track]:rounded-full
              [&::-webkit-slider-runnable-track]:bg-[#F2F2F2]
              [&::-webkit-slider-thumb]:size-[0.75rem]
              [&::-webkit-slider-thumb]:translate-y-[-35%]

              [&::-webkit-slider-thumb]:appearance-none
              [&::-webkit-slider-thumb]:rounded-full
              [&::-webkit-slider-thumb]:bg-[#222222]
              [&::-webkit-slider-thumb]:transition-all

              [&::-webkit-slider-thumb]:duration-150
              [&::-webkit-slider-thumb]:ease-in-out" />
      <input
          ref="inputMaxVal"
          v-model="currentMax"
          type="range"
          :min="currentMin"
          :max="maxVal"
          :step="step"
          class="absolute right-0 top-0 cursor-pointer appearance-none focus:outline-none disabled:pointer-events-none disabled:opacity-50
              [&::-moz-range-thumb]:size-[0.75rem]
              [&::-moz-range-thumb]:appearance-none

              [&::-moz-range-thumb]:rounded-full
              [&::-moz-range-thumb]:bg-[#222222]
              [&::-moz-range-thumb]:transition-all
              [&::-moz-range-thumb]:duration-150
              [&::-moz-range-thumb]:ease-in-out
              [&::-moz-range-track]:mb-[#1rem]
              [&::-moz-range-track]:h-[0.25rem]
              [&::-moz-range-track]:w-full
              [&::-moz-range-track]:rounded-r-full

              [&::-moz-range-track]:bg-[#F2F2F2]
              [&::-webkit-slider-container]:w-[1px]
              [&::-webkit-slider-container]:translate-y-[0.375rem]
              [&::-webkit-slider-runnable-track]:h-[0.25rem]
              [&::-webkit-slider-runnable-track]:w-full
              [&::-webkit-slider-runnable-track]:translate-y-[0,375rem]
              [&::-webkit-slider-runnable-track]:rounded-r-full
              [&::-webkit-slider-runnable-track]:bg-[#F2F2F2]

              [&::-webkit-slider-thumb]:size-[0.75rem]
              [&::-webkit-slider-thumb]:translate-y-[-35%]
              [&::-webkit-slider-thumb]:appearance-none
              [&::-webkit-slider-thumb]:rounded-full
              [&::-webkit-slider-thumb]:bg-[#222222]

              [&::-webkit-slider-thumb]:transition-all
              [&::-webkit-slider-thumb]:duration-150
              [&::-webkit-slider-thumb]:ease-in-out" />
    </div>
    <label class="mb-[0.5rem] block text-[0.875rem] font-[600]">{{ `Max: ${parseInt(currentMax) === 1000 ? 'unbegrenzt' : (parseInt(currentMax)).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}` }}</label>
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue'
import {updateFilter} from "@store/listingFilterStore.js";

  const props = defineProps({
    filterKey: {
      type: String,
      required: true,
    },
    minVal: {
      type: Number,
      default: 1,
    },
    maxVal: {
      type: Number,
      default: 5,
    },
    step: {
      type: Number,
      default: 1,
    }
  });

  const currentMin = ref(props.minVal);
  const currentMax = ref(props.maxVal);
  const delayedInput = ref(null);

  const inputMaxVal = ref(null);

  onMounted(() => {
    calculateWidthPercentage();
  });

  watch(currentMin, (newVal) => {
    if (parseInt(newVal) >= (parseInt(currentMax.value) - parseInt(props.step))) {
      currentMin.value = parseInt(currentMax.value) - parseInt(props.step);
      newVal = parseInt(currentMax.value) - parseInt(props.step);
    }

    calculateWidthPercentage();

    if (delayedInput.value !== null) {
      clearTimeout(delayedInput.value);
    }

    delayedInput.value = setTimeout(() => {
      updateListingFilter(parseInt(newVal), parseInt(currentMax.value));
    }, 750);
  });

  watch(currentMax, (newVal) => {
    if (parseInt(newVal) <= (parseInt(currentMin.value) + parseInt(props.step))) {
      currentMax.value = parseInt(currentMin.value) + parseInt(props.step);
      newVal = parseInt(currentMin.value) + parseInt(props.step);
    }

    if (delayedInput.value !== null) {
      clearTimeout(delayedInput.value);
    }

    delayedInput.value = setTimeout(() => {
      updateListingFilter(parseInt(currentMin.value), parseInt(newVal));
    }, 750);
  });

  const updateListingFilter = (minValue, maxValue) => {
    updateFilter({key: props.filterKey, value: [minValue, maxValue]})
  };

  function calculateWidthPercentage () {
    const widthPercentage = (1 - parseInt(currentMin.value) / parseInt(props.maxVal));
    let modifier = 2 * widthPercentage;

    if (navigator.userAgent.toLowerCase().includes('safari')) {
      modifier -= 0.75;
    } else {
      modifier -= 0.9;
    }

    inputMaxVal.value.style.width = `calc(${widthPercentage * 100}% - ${modifier}rem)`;
  }
</script>